import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination, Navigation } from "swiper"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"


const Cinemagic = ({ data, location }) => {
  const url = `https://speedcambrasil.com.br${location.pathname}`
  let carouselImages = []
  data.allCarouselInnovision.edges.forEach(element => {
    carouselImages.push({ img: getImage(element["node"]) })
  })

  const makeCarousel = () => {
    return (
      <Swiper
        slidesPerView={1}
        loop={true}
        spaceBetween={30}
        centeredSlides={true}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {carouselImages.map((image, k) => {
          return (
            <SwiperSlide key={k}>
              <GatsbyImage
                image={image.img}
                backgroundColor={false}
                alt="Alexa Mini"
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    )
  }

  return (
    <>
      <Metatags
        title="Speedcam Brasil - Cinemagic Revolution"
        description="Speed Cam - Rental House"
        url={url}
        image="https://speedcambrasil.com.br/logo.png"
        keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
        language="pt-br"
      />
      <NavBar />
      <div className="container mx-auto text-center">
        <h1 className="text-4xl m-4 md:text-6xl font-semibold text-sc-yellow">
          <i>Cinemagic Revolution</i>
        </h1>
        <div className="h-20" />
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">{makeCarousel()}</div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl m-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8">
            <p>
              Esse sistema de lentes para snorkel inclui lentes objetivas
              Mini-PL de 9,8 mm, 12 mm, 16 mm, 20 mm, 24 mm, 32 mm, 40 mm, 60 mm
              e também pode usar lentes de montagem PL padrão com o adaptador de
              montagem PL incluído.
            </p>

            <p>
              Ao usar o módulo Horizon Tracker, o horizonte pode ser definido na
              posição desejada e travado. Essa inovação permite que a lente
              objetiva do snorkel seja panorâmica enquanto o horizonte permanece
              no nível ou na posição que você escolheu. Um diâmetro de seis ou
              sete polegadas é tudo o que é necessário para atingir a panorâmica
              de 360 graus, tornando este dispositivo ideal para fotos de mesa
              ou em miniatura!
            </p>
            <p>
              O módulo Dual Swivel adiciona um segundo eixo panorâmico ao
              sistema de lentes. Nesse caso, o bloqueio de horizonte não é
              usado. O horizonte pode ser ajustado manualmente ou
              eletronicamente.
            </p>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-3" />
          <div className="col-span-8">
            <div>
              <h1 className="my-2">Principais características:</h1>

              <ul className="list-disc list-inside">
                <li>Capture pontes de vista únicos</li>
                <li>Gradação T7.5 para uso com película</li>
                <li>Apta para filtros de 40.5mm</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer />
    </>
  )
}

export default Cinemagic

export const query = graphql`
  {
    allCarouselInnovision: allFile(
      filter: { relativePath: { regex: "/cinemagic-revolution/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1536)
          }
        }
      }
    }
  }
`
